<template>
  <div :class="{ popup_wrap : okHidden }" style="width:700px">
    <!-- popup_wrap : style="width:700px height:550px" 참고용, 개발시 제거 -->
    <button v-if="okHidden" class="layer_close" @click="$emit('close', 'rs')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.SPEC020G010.000') }}</h1>

      <div class="content_box">
        <!-- content_box -->

        <table class="tbl_row">
          <colgroup>
            <col width="25%">
            <col width="25%">
            <col width="25%">
            <col width="25%">
          </colgroup>
          <tbody v-if="oogListData !== null">
            <tr>
              <th>REQ No.</th>
              <!-- <td colspan="3">{{ oogListData.reqNo }}</td> -->
              <select name="req" id="req" @change="changeReqNo">
                <option v-for="(vo, i) in reqNos" :key="i" :value="vo">{{ vo }}</option>
              </select>
              <!-- {{ reqNos }} -->
            </tr>
            <tr>
              <th>Container Size</th>
              <td>{{ oogListData[0].cntrSzCd }}</td>
              <th>Container Type</th>
              <td>{{ oogListData[0].cntrTypCd }}</td>
            </tr>
            <tr>
              <th>POL</th>
              <td v-if="oogListData[0].polTrmlCd == undefined || oogListData[0].polTrmlCd === null">{{ oogListData[0].polCtrCd }} / {{ oogListData[0].polPortCd }}</td>
              <td v-else>
                {{ oogListData[0].polCtrCd }} / {{ oogListData[0].polPortCd }} <br>
                / {{ oogListData[0].polTrmlCd }}
              </td>
              <th>POD</th>
              <td v-if="oogListData[0].podTrmlCd == undefined || oogListData[0].podTrmlCd === null">{{ oogListData[0].podCtrCd }} / {{ oogListData[0].podPortCd }}</td>
              <td v-else>
                {{ oogListData[0].podCtrCd }} / {{ oogListData[0].podPortCd }} <br>
                / {{ oogListData[0].podTrmlCd }}
              </td>
            </tr>
          </tbody>
        </table>

        <table class="tbl_col mt10">
          <colgroup>
            <col width="25%">
            <col width="25%">
            <col width="25%">
            <col width="25%">
          </colgroup>
          <tbody v-if="oogListData !== null">
            <tr>
              <th>Length (cm)</th>
              <td>{{ $ekmtcCommon.changeNumberFormat(oogListData[0].len, { isComma: true }) }}</td>

              <th>Width (cm)</th>
              <td>{{ $ekmtcCommon.changeNumberFormat(oogListData[0].widt, { isComma: true }) }}</td>
            </tr>
            <tr>
              <th>Height (cm)</th>
              <td>{{ $ekmtcCommon.changeNumberFormat(oogListData[0].hght, { isComma: true }) }}</td>

              <th>Weight (kg)</th>
              <td>{{ $ekmtcCommon.changeNumberFormat(oogListData[0].frgtWt, { isComma: true }) }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.NEWB010P120.009') }}</th>
              <td>
                {{ oogListData[0].cmdtNm }}
              </td>

              <th>Status</th>
              <td>
                <span v-if="oogListData[0].apvStsCd ==='01'" class="label green md">{{ $t('msg.CSBK140.001') }}</span>
                <span v-else-if="oogListData[0].apvStsCd ==='02'" class="label blue md">{{ $t('msg.CSBK140.002') }}</span>
                <span v-else-if="oogListData[0].apvStsCd ==='03'" class="label red md">{{ $t('msg.CSBK140.003') }}</span>
                <span v-else-if="oogListData[0].apvStsCd ==='04'" class="label gray md">{{ $t('msg.CSBK140.004') }}</span>
              </td>
            </tr>
            <tr>
              <th>Remarks</th>
              <td v-if="oogListData != null" colspan="3">
                {{ oogListData[0].rmk }}
              </td>
            </tr>
          </tbody>
        </table>

        <table class="tbl_col mt10">
          <colgroup>
            <col width="6%">
            <col width="6%">
            <col width="6%">
            <col width="6%">

            <col width="6%">
            <col width="6%">
            <col width="6%">
            <col width="6%">

            <col width="6%">
            <col width="6%">
            <col width="6%">
            <col width="6%">

            <col width="14">
            <col width="14">
          </colgroup>
          <thead>
            <tr>
              <th colspan="4">POL</th>
              <th colspan="4">POD</th>
              <th colspan="4">T/S</th>
              <th rowspan="2">Load</th>
              <th rowspan="2">Best Lashing</th>
            </tr>
            <tr>
              <th>L</th>
              <th>W</th>
              <th>H</th>
              <th>WGT</th>
              <th>L</th>
              <th>W</th>
              <th>H</th>
              <th>WGT</th>
              <th>L</th>
              <th>W</th>
              <th>H</th>
              <th>WGT</th>
            </tr>
          </thead>
          <tbody v-if="oogInfo !== null">
            <tr>
              <td :class="{ 'color_red' : isState(oogInfo.evltPolLen) }">{{ oogInfo.evltPolLen }}</td>
              <td :class="{ 'color_red' : isState(oogInfo.evltPolWidt) }">{{ oogInfo.evltPolWidt }}</td>
              <td :class="{ 'color_red' : isState(oogInfo.evltPolHght) }">{{ oogInfo.evltPolHght }}</td>
              <td :class="{ 'color_red' : isState(oogInfo.evltPolFrgtWt) }">{{ oogInfo.evltPolFrgtWt }}</td>
              <td :class="{ 'color_red' : isState(oogInfo.evltPodLen) }">{{ oogInfo.evltPodLen }}</td>
              <td :class="{ 'color_red' : isState(oogInfo.evltPodWidt) }">{{ oogInfo.evltPodWidt }}</td>
              <td :class="{ 'color_red' : isState(oogInfo.evltPodHght) }">{{ oogInfo.evltPodHght }}</td>
              <td :class="{ 'color_red' : isState(oogInfo.evltPodFrgtWt) }">{{ oogInfo.evltPodFrgtWt }}</td>
              <td :class="{ 'color_red' : isState(oogInfo.evltTsLen) }">{{ oogInfo.evltTsLen }}</td>
              <td :class="{ 'color_red' : isState(oogInfo.evltTsWidt) }">{{ oogInfo.evltTsWidt }}</td>
              <td :class="{ 'color_red' : isState(oogInfo.evltTsHght) }">{{ oogInfo.evltTsHght }}</td>
              <td :class="{ 'color_red' : isState(oogInfo.evltTsFrgtWt) }">{{ oogInfo.evltTsFrgtWt }}</td>
              <td> {{ oogInfo.evltLoadCap }}</td>
              <td><a @click="fileDown()" class="no-uline">{{ oogInfo.fileNm }}</a></td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import Oogs from '@/api/rest/trans/oogs'

export default {
    name: 'OogDetailPop',
    props: {
      detail: {
        type: Object,
        default: function () {
          return {
            reqNos: []
          }
        }
      },
      okHidden: {
        type: Boolean
      }
    },
    data: function () {
        return {
          parentInfo: {},
          customComponent: null,
          customPop: null,
          selectFunc: null,
          uploadParam: [],
          uploadFileName: '',
          uploadFileInfo: [],
          state1: '',
          link: '',
          idx: '',

          // reqNos: this.detail.reqNos,
          oogListData: null,
          oogInfo: null
        }
    },
    computed: {
      reqNos: function () {
          return this.detail.reqNos ? this.detail.reqNos : ''
      }
    },
    watch: {
      reqNos: function () {
          this.changeReqNo({ target: { value: this.detail.reqNos[0] } })
      }
    },
    created () {
    },
    mounted () {
      this.changeReqNo({
        target: {
          value: this.reqNos[0]
        }
      })
    },
    methods: {
      changeReqNo (e) {
        console.log('search detail')
        let detail = []

        this.idx = e.target.value
        detail = Oogs.getOogDetail(this.idx)

        detail.then((data) => {
            const detailData = data.data

            this.oogListData = detailData.oogList
            this.oogInfo = detailData.oogInfo

            console.log('oogListData ::: ', this.oogListData)
            //console.log('oogInfo ::: ', this.oogInfo)
        })
      },
      isState: function (state) {
          if (state === 'X') {
              return true
          }
          return false
      },
      fileDown () {
          //console.log('fileDown')
          //console.log(this.oogListData[0].cmdtCd)
          Oogs.oogFileDown(this.oogListData[0].cmdtCd)
      }
    }
}

</script>

<style scoped>
a.no-uline:hover{
  text-decoration:underline
}
</style>
