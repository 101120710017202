var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { popup_wrap: _vm.okHidden }, staticStyle: { width: "700px" } },
    [
      _vm.okHidden
        ? _c(
            "button",
            {
              staticClass: "layer_close",
              on: {
                click: function ($event) {
                  return _vm.$emit("close", "rs")
                },
              },
            },
            [_vm._v("close")]
          )
        : _vm._e(),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.SPEC020G010.000"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(0),
            _vm.oogListData !== null
              ? _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("REQ No.")]),
                    _c(
                      "select",
                      {
                        attrs: { name: "req", id: "req" },
                        on: { change: _vm.changeReqNo },
                      },
                      _vm._l(_vm.reqNos, function (vo, i) {
                        return _c(
                          "option",
                          { key: i, domProps: { value: vo } },
                          [_vm._v(_vm._s(vo))]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Container Size")]),
                    _c("td", [_vm._v(_vm._s(_vm.oogListData[0].cntrSzCd))]),
                    _c("th", [_vm._v("Container Type")]),
                    _c("td", [_vm._v(_vm._s(_vm.oogListData[0].cntrTypCd))]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("POL")]),
                    _vm.oogListData[0].polTrmlCd == undefined ||
                    _vm.oogListData[0].polTrmlCd === null
                      ? _c("td", [
                          _vm._v(
                            _vm._s(_vm.oogListData[0].polCtrCd) +
                              " / " +
                              _vm._s(_vm.oogListData[0].polPortCd)
                          ),
                        ])
                      : _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.oogListData[0].polCtrCd) +
                              " / " +
                              _vm._s(_vm.oogListData[0].polPortCd) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " / " + _vm._s(_vm.oogListData[0].polTrmlCd) + " "
                          ),
                        ]),
                    _c("th", [_vm._v("POD")]),
                    _vm.oogListData[0].podTrmlCd == undefined ||
                    _vm.oogListData[0].podTrmlCd === null
                      ? _c("td", [
                          _vm._v(
                            _vm._s(_vm.oogListData[0].podCtrCd) +
                              " / " +
                              _vm._s(_vm.oogListData[0].podPortCd)
                          ),
                        ])
                      : _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.oogListData[0].podCtrCd) +
                              " / " +
                              _vm._s(_vm.oogListData[0].podPortCd) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " / " + _vm._s(_vm.oogListData[0].podTrmlCd) + " "
                          ),
                        ]),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("table", { staticClass: "tbl_col mt10" }, [
            _vm._m(1),
            _vm.oogListData !== null
              ? _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Length (cm)")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$ekmtcCommon.changeNumberFormat(
                            _vm.oogListData[0].len,
                            { isComma: true }
                          )
                        )
                      ),
                    ]),
                    _c("th", [_vm._v("Width (cm)")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$ekmtcCommon.changeNumberFormat(
                            _vm.oogListData[0].widt,
                            { isComma: true }
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Height (cm)")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$ekmtcCommon.changeNumberFormat(
                            _vm.oogListData[0].hght,
                            { isComma: true }
                          )
                        )
                      ),
                    ]),
                    _c("th", [_vm._v("Weight (kg)")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$ekmtcCommon.changeNumberFormat(
                            _vm.oogListData[0].frgtWt,
                            { isComma: true }
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB010P120.009")))]),
                    _c("td", [
                      _vm._v(" " + _vm._s(_vm.oogListData[0].cmdtNm) + " "),
                    ]),
                    _c("th", [_vm._v("Status")]),
                    _c("td", [
                      _vm.oogListData[0].apvStsCd === "01"
                        ? _c("span", { staticClass: "label green md" }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK140.001"))),
                          ])
                        : _vm.oogListData[0].apvStsCd === "02"
                        ? _c("span", { staticClass: "label blue md" }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK140.002"))),
                          ])
                        : _vm.oogListData[0].apvStsCd === "03"
                        ? _c("span", { staticClass: "label red md" }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK140.003"))),
                          ])
                        : _vm.oogListData[0].apvStsCd === "04"
                        ? _c("span", { staticClass: "label gray md" }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK140.004"))),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Remarks")]),
                    _vm.oogListData != null
                      ? _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(" " + _vm._s(_vm.oogListData[0].rmk) + " "),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("table", { staticClass: "tbl_col mt10" }, [
            _vm._m(2),
            _vm._m(3),
            _vm.oogInfo !== null
              ? _c("tbody", [
                  _c("tr", [
                    _c(
                      "td",
                      {
                        class: {
                          color_red: _vm.isState(_vm.oogInfo.evltPolLen),
                        },
                      },
                      [_vm._v(_vm._s(_vm.oogInfo.evltPolLen))]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          color_red: _vm.isState(_vm.oogInfo.evltPolWidt),
                        },
                      },
                      [_vm._v(_vm._s(_vm.oogInfo.evltPolWidt))]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          color_red: _vm.isState(_vm.oogInfo.evltPolHght),
                        },
                      },
                      [_vm._v(_vm._s(_vm.oogInfo.evltPolHght))]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          color_red: _vm.isState(_vm.oogInfo.evltPolFrgtWt),
                        },
                      },
                      [_vm._v(_vm._s(_vm.oogInfo.evltPolFrgtWt))]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          color_red: _vm.isState(_vm.oogInfo.evltPodLen),
                        },
                      },
                      [_vm._v(_vm._s(_vm.oogInfo.evltPodLen))]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          color_red: _vm.isState(_vm.oogInfo.evltPodWidt),
                        },
                      },
                      [_vm._v(_vm._s(_vm.oogInfo.evltPodWidt))]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          color_red: _vm.isState(_vm.oogInfo.evltPodHght),
                        },
                      },
                      [_vm._v(_vm._s(_vm.oogInfo.evltPodHght))]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          color_red: _vm.isState(_vm.oogInfo.evltPodFrgtWt),
                        },
                      },
                      [_vm._v(_vm._s(_vm.oogInfo.evltPodFrgtWt))]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          color_red: _vm.isState(_vm.oogInfo.evltTsLen),
                        },
                      },
                      [_vm._v(_vm._s(_vm.oogInfo.evltTsLen))]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          color_red: _vm.isState(_vm.oogInfo.evltTsWidt),
                        },
                      },
                      [_vm._v(_vm._s(_vm.oogInfo.evltTsWidt))]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          color_red: _vm.isState(_vm.oogInfo.evltTsHght),
                        },
                      },
                      [_vm._v(_vm._s(_vm.oogInfo.evltTsHght))]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          color_red: _vm.isState(_vm.oogInfo.evltTsFrgtWt),
                        },
                      },
                      [_vm._v(_vm._s(_vm.oogInfo.evltTsFrgtWt))]
                    ),
                    _c("td", [_vm._v(" " + _vm._s(_vm.oogInfo.evltLoadCap))]),
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "no-uline",
                          on: {
                            click: function ($event) {
                              return _vm.fileDown()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.oogInfo.fileNm))]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "6%" } }),
      _c("col", { attrs: { width: "14" } }),
      _c("col", { attrs: { width: "14" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { colspan: "4" } }, [_vm._v("POL")]),
        _c("th", { attrs: { colspan: "4" } }, [_vm._v("POD")]),
        _c("th", { attrs: { colspan: "4" } }, [_vm._v("T/S")]),
        _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Load")]),
        _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Best Lashing")]),
      ]),
      _c("tr", [
        _c("th", [_vm._v("L")]),
        _c("th", [_vm._v("W")]),
        _c("th", [_vm._v("H")]),
        _c("th", [_vm._v("WGT")]),
        _c("th", [_vm._v("L")]),
        _c("th", [_vm._v("W")]),
        _c("th", [_vm._v("H")]),
        _c("th", [_vm._v("WGT")]),
        _c("th", [_vm._v("L")]),
        _c("th", [_vm._v("W")]),
        _c("th", [_vm._v("H")]),
        _c("th", [_vm._v("WGT")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }